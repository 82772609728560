.nav-panel {
    height: 56px;
    background: #fff;
    // box-shadow: 0 1px rgba(0 0 0 / 4%), inset 0 -1px rgba(0 0 0 / 4%);
    color: $coal-black;
    border: 1px solid $grey-light;

    .nav-link {
        color: $coal-black;
        font-size: .875rem;
    }
}

.nav-panel__row {
    display: flex;
    align-items: center;
    position: relative;
    height: 100%;

    @include media-breakpoint-down(lg) {
        flex-direction: column;
    }
}

.nav-panel__departments {
    flex-shrink: 0;

    @include media-breakpoint-up(lg) {
        margin-left: -.75rem;
    }
    @include media-breakpoint-down(lg) {
        width: 100%;
    }
}

.departments {
    // width: 255px;
    // @extend .col-xl-3;
    // @extend .col-lg-4;
    z-index: 3;

    @include media-breakpoint-up(lg) {
        width: calc((#{map-get($container-max-widths, "lg")} / 3) - .75rem);
    }

    @include media-breakpoint-up(xl) {
        width: calc((#{map-get($container-max-widths, "xl")} * .25) - .75rem);
    }

    @include media-breakpoint-up(xxl) {
        width: calc((#{map-get($container-max-widths, "xxl")} * .25) - .75rem);
    }

    @include media-breakpoint-up(xxxl) {
        width: calc((#{map-get($container-max-widths, "xxxl")} * .25) - .75rem);
    }

    height: 56px;
    // color: $departments-font-color;
    position: relative;
}

.departments__body {
    width: 100%;
    // padding-top: 56px;
    padding-top: 54px;
    position: absolute;
    background: #fff;
    // box-shadow: $departments-shadow;
    border: 1px solid $grey-light;
    border-radius: 0px 0px 4px 4px;
    top: 0;

    @include media-breakpoint-down(lg) {
        display: none;
    }
}

.megamenu__item a,
.departments__item-link {
    display: flex;

    img {
        margin-right: .5rem;
        max-width: 100%;
    }
    @include media-breakpoint-down(lg) {
        color: $coal-black;
        padding: .5rem 1.5rem;
        img {
            margin-right: 1rem;
        }
    }
}

.departments__item-link {
    @include hover-focus() {
        color: $white;
        background-color: $navy;

        img {
            filter: brightness(0) invert(1);
        }
    }
}

.megamenu__item a {
    @include hover-focus() {
        color: $navy;
    }
}

.departments__links {
    position: relative;

    // reset list
    list-style: none;
    padding: 0 0 14px;
    margin: 0;
    // overflow-y: auto;
    // overscroll-behavior-y: contain;
    // -webkit-overflow-scrolling: touch;
    // overflow-scrolling: touch;
}


.departments__links-wrapper {
    @include media-breakpoint-up(lg) {
        overflow: hidden;
        height: 0;
        opacity: 0;
    }
    @include media-breakpoint-down(lg) {
        overflow: visible;
        height: auto;
        opacity: 1;
    }

    // transition: height $local-transition-duration ease-in-out, opacity $local-transition-duration ease-in-out;
    display: flex;
    flex-direction: column;
    max-height: 100%;


    &:before {
        display: block;
        content: '';
        height: 6px;
        flex-shrink: 0;
    }
}

.departments--open {
    .departments__links-wrapper {
        overflow: visible;
        height: auto;
        opacity: 1;
    }
}

.departments__button {
    border: none;
    background: transparent;
    color: $coal-black;
    // font-size: 15px;
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100%;
    cursor: default;
    // font-weight: $font-weight-medium;
    padding: 0;
    appearance: none;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid $grey-light;

    @include media-breakpoint-up(lg) {
        border-right: 1px solid $grey-light;
    }
    // @include media-breakpoint-down(lg) {
    //     border-bottom: 1px solid $grey-light;
    // }

    i {
        margin-right: .5rem;
    }

    &:focus {
        outline: none;
    }
}

.departments__submenu {
    position: absolute;
    top: 0;
    visibility: hidden;
    display: none;
    z-index: 100;
    width: 100%;
    left: calc(100% + 1rem);
    background-color: #fff;
    width: 100%;

    @include media-breakpoint-up(lg) {
        min-width: 612px;
        box-shadow: 0px 4px 4px rgba(216, 216, 216, 0.12);
        border-radius: 4px;
        border-left: 4px solid $primary;
    }

}

.megamenu--departments {
    width: 100%;

    .col-lg-6 {
        &:not(:last-child) {
            border-right: 1px solid $grey-light;
        }
    }
}

.nav-panel__nav-links {
    width: 100%;

    .nav-link {
        padding: 1rem .5rem;
        color: $coal-black;
        font-size: 1rem;
    }

    .nav-item {
        &:not(:last-child) {
            .nav-link {
                @include media-breakpoint-up(lg) {
                    border-right: 1px solid $grey-light;
                }
                @include media-breakpoint-down(lg) {
                    border-bottom: 1px solid $grey-light;
                }
            }
        }

        &:last-child {
            .nav-link {
                color: $danger;
            }
        }
    }
}

.departments__item {
    position: relative;
    &:hover {
        .departments__submenu {
            visibility: visible;
            opacity: 1;
            display: flex;
        }
    }
}



// .list-wrapper {
//     @include media-breakpoint-up(lg) {
//         width: 100%;

//         &:nth-child(2),
//         &:nth-child(3),
//         &:nth-child(4) {
//             display: none;
//         }
//     }
// }



.back-one-level {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    color: $coal-black;
    position: relative;
    border-bottom: 1px solid $grey-light;
    border-radius: 0;
    font-size: 1rem;
    font-weight: 400;
    justify-content: flex-start;
    padding-left: 3.8rem;

    &:before {
        content: "\e317";
        @extend .material-icons;
        font-size: 1.5rem;
        vertical-align: -0.255em;
        transition: all .3s;
        color: $coal-black;
        left: 1.5rem;
        position: absolute;
    }
}

