.timeline {
    margin: 50px 0;
    text-align: center;
    align-items: baseline;
    .date {
        white-space: normal;
        min-height: 3.5em;
        @include media-breakpoint-down(sm) {
            min-height: 4.5em;
        }
    }
}

.timestamp {
    width: 100%;
    margin-bottom: 20px;
    padding: 0px 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: 400;

}

.status {
    padding: 0px 40px;
    display: flex;
    justify-content: center;
    border-top: 1px solid rgba($navy, .4);
    position: relative;
    transition: all 200ms ease-in;
    width: 100%;

    span {
        font-weight: 400;
        padding-top: 18px;

        strong {
            font-weight: 700;
            padding-top: 20px;
            color: $navy;
        }

        &:before {
            content: '';
            width: 12px;
            height: 12px;
            background-color: $navy;
            border-radius: 4px;
            position: absolute;
            top: -6px;
            left: calc(50% - 12px);
            transition: all 200ms ease-in;
        }
    }
}

.timelineSwiper {
    .swiper-slide {
        flex-direction: column;
    }
}