.search_filters_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 3rem;
    h2 {
        margin-bottom: 0;
        font-weight: 400;
    }

    .btn {
        margin-left: .5rem;
        font-weight: 400;
        span {
            font-size: 1rem;
        }
    }
}

.icon-control {
    float: right;
    font-size: 1rem;
    color: $grey-darker;

    &:before {
        content: "\e313";
    }
}

a[aria-expanded="true"] {
    .icon-control:before {
        content: "\e316";
    }
}

.magnitude {
    // float: right;
    // margin-left: .5rem;
    position: absolute;
    right: 0;
    top: .05rem;
    color: $grey-dark;
    font-size: .875rem;
}

.filter-group {
    // border-bottom: 1px solid $grey-light;
    // font-size: .938rem;
    margin-bottom: 1.5rem;
    background-color: #fff;
    border-radius: $border-radius;
    .form-check {
        margin-bottom: .5rem;
    }

    .card-header {
        border-bottom: 0;
        background-color: transparent;
        // padding-left: 0;
        // padding-right: 0;
        padding: .75rem 1rem;
        a {
            text-decoration: none;
        }

        small {
            font-weight: 400;
            font-size: 12px;
            display: block;
            margin-top: .25rem;
        }

        .form-switch {
            float: right;
        }

        .title {
            font-weight: 500;
            // font-size: 15px;
            color: $grey-darker;
            margin-bottom: 0;
        }

    }

    &:last-child {
        border-bottom: 0
    }
}
.title-w-img {
    display: flex;
    align-items: center;
    img {
        max-height: 20px;
        width: auto;
        margin-right: .5rem;
    }
}

.filter-content {
    // color: $shades-grey;
    .form-check-label {
        padding-right: 2rem;
        position: relative;
    }    
}


.search_filters_wrapper {
    @include media-breakpoint-down(lg) {
        opacity: 0;

        &.show {
            opacity: 1;
        }
    }

    .card {
        box-shadow: none;
        background-color: transparent;
    }

    .card-body {
        padding: 0 1rem 1rem;
        // padding-bottom: 1.25rem;
    }

    h2 {
        font-size: 18px;
        // color: $shades-grey;
        margin-bottom: 0;
    }

    .js-search-toggler {
        // background-color: $primary;
        // position: absolute;
        // left: -70px;
        // top: 0;
        // border-radius: 0;
        // width: 70px;
        // height: 70px;
        // padding: 0;
        // color: #000;

        span {
            font-size: 1.5rem;
            color: $grey;
        }
    }

    @include media-breakpoint-down(lg) {
        position: fixed;
        bottom: 0;
        left: 100%;
        right: 0;
        z-index: 11;
        // width: 100%;
        background: #F6F6F6;
        transition: 0.5s;
        padding: 0 20px;
        // width: calc(100% - 70px);
        width: 100%;
        top: 163px;
        // top: 0;

        &.show {
            // left: 70px;
            left: 0;
            // overflow: visible;
            // overflow-y: scroll;
            // overflow-x: auto;
            .card-footer {
                // left: 70px;
                left: 0;
            }
        }

        .card {
            height: 100%;
            overflow-y: scroll;
            overflow-x: hidden;
            padding-bottom: 13rem;
        }
    }

    .card-footer {
        background-color: $white;
        position: fixed;
        bottom: 0;
        padding: 1.5rem;
        // margin: 0 -20px;
        z-index: 2;
        left: 100%;
        // width: calc(100% - 70px);
        width: 100%;
        transition: 0.5s;

        .btn {
            display: block;
            width: 100%;
            // border-radius: 0;
        }

        @include media-breakpoint-up(lg) {
            display: none;
        }
    }
}


.image-checkbox {
    padding-left: 0;
    display: inline-block;

    .image {
        width: 70px;
        height: 70px;
        border: 2px solid transparent;
        display: inline-block;
        // background-size: contain;
        background-size: 95%;
        background-repeat: no-repeat;
        background-position: center;
        border-radius: $border-radius;
    }

    input {
        opacity: 0;

        &:checked {
            ~.image {
                border-color: $primary;
            }
        }
    }
}

.reset-group {
    display: flex;
    align-items: center;
    text-decoration: none;
    font-weight: 400;
    font-size: .875rem;
    margin-top: .5rem;
    span {
        font-size: 1rem;
    }
}

.range-slider {
    margin-bottom: 1rem;
}


.noUi-connect {
    background: $primary !important;
    height: 2px !important;
}

.noUi-handle {
    cursor: pointer !important;
    // border-radius: 50% !important;
    border-radius: 2px;
    width: 10px !important;
    height: 10px !important;
    outline: none !important;
    box-shadow: none !important;
    background-color: #fff !important;
    border: 2px solid $primary !important;
    top: -4px !important;
    right: -5px !important;
}

.noUi-target {
    background-color: transparent !important;
    border: none !important;
}

.noUi-horizontal {
    height: 2px !important;
    box-shadow: none !important;
    margin-top: 1rem;
    // margin-bottom: 3rem;
}

.noUi-handle:before,
.noUi-handle:after {
    display: none !important;
}

.noUi-connects {
    background: rgba($navy, .15);
}

.noUi-horizontal .noUi-tooltip {
    border-color: transparent;
    background-color: transparent;
    color: $primary;
    font-size: .875rem;
    bottom: initial!important;
    top: 120%;

}
.noUi-handle-lower {
    .noUi-tooltip {
        transform: none!important;
        left: -5px!important;
        // right: 0;
    }
}
.noUi-handle-upper {
    .noUi-tooltip {
        transform: none!important;
        left: initial!important;
        right: -5px;
    }
}
.range-val-max,
.range-val-min {
    // border: none;
    padding: 0 .25rem;
    color: $primary;
    width: inherit;
    text-align: center;
}
.range-val-max {
    margin-left: auto;
}