$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1140px,
    xxl: 1280px,
    xxxl: 1760px
);
$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1439px,
    xxxl: 1919px
);

$grid-gutter-width: 3rem;
// colors
$primary: #011A57;
$secondary: #E80027;
$danger: #E80027;
$success: #00A12D;
$info: #009BF2;
$warning: #FDBA0B;
$navy: #012978;
// $body-color: #0F0F0F;
$themeColor: $primary;

$grey-darker: #565656;
$grey-dark: #8A8A8A;
$grey: #CACACA;
$grey-light: #E1E1E1;
$grey-lighter: #F9F9F9;

$blue: #0073CF;
$blue-light: #F3F7FF;
$blue-lighter: #F9FAFF;

$coal-black: #221F20;
$black: #051723;

//fonts

$body-color: $grey-darker;
$font-family-base: 'DM Sans', sans-serif;
// $body-color: $grey-darker:
$headings-color: $primary;
$headings-margin-bottom: .5em;
// $headings-font-weight: 400;
$h1-font-size: 28px;
$h2-font-size: 22px;
$h3-font-size: 18px;
$h4-font-size: 18px;
// $h5-font-size: 14px;
// $h6-font-size: 12px;

//icons
$material-icons-font-path: './../fonts/material/' !default;

//buttons
$btn-font-weight: 700;
$btn-font-size: .875rem;
$btn-padding-y: .813rem;
$btn-padding-x: 1.563rem;
// $btn-border-radius: 4px;
$btn-focus-box-shadow: none;
$btn-active-box-shadow: none;

$enable-shadows: false;

//forms
$input-font-size: .875rem;
$input-padding-y: .782rem;
$input-padding-x: 1rem;
$input-border-color: $grey-light;

//breadcrumbs
$breadcrumb-active-color: $primary;
$breadcrumb-font-size: 14px;
// $breadcrumb-divider: url("data:image/svg+xml,%3Csvg width='6' height='10' viewBox='0 0 6 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 9L5 5L1 1' stroke='currentColor' stroke-linecap='round'/%3E%3C/svg%3E%0A");
$breadcrumb-divider: quote(">");
$breadcrumb-active-color: $grey-darker;

$border-radius: 4px;

//cards
$card-spacer-y: 1.5rem;
$card-spacer-x: 1.5rem;
$card-border-color: transparent;
$card-box-shadow: 4px 4px 4px rgba(216, 216, 216, 0.12);

//tabs
$nav-tabs-link-active-bg: #fff;
$nav-tabs-border-radius: 0;
$nav-link-color: $navy;
$nav-tabs-link-active-color: $navy;
// $nav-link-padding-y: 1.875rem;
$nav-tabs-border-color: transparent;  
$nav-tabs-border-width: 0px;
$nav-tabs-link-active-border-color: transparent;
$nav-tabs-link-hover-border-color: transparent;
$nav-tabs-link-active-bg: transparent;

//pagination
$pagination-color: $body-color;
$pagination-padding-y: 0;
$pagination-padding-x: 0;
$pagination-hover-color:            $primary;
$pagination-hover-bg:               $warning;
$pagination-hover-border-color:     $warning;
$pagination-focus-color:            $primary;
$pagination-focus-bg:               $warning;
$pagination-focus-outline:          0;
$pagination-bg: transparent;
$pagination-disabled-bg: transparent;
$pagination-hover-color: #fff;


$accordion-border-color: transparent;
$accordion-bg: transparent;
$accordion-button-bg: $primary;
$accordion-button-active-bg: $primary;
$accordion-button-color:  #fff;
$accordion-button-active-color: #fff;
$accordion-icon-color: #fff;
$accordion-icon-active-color:#fff;
$accordion-padding-y: 1.5rem;
$accordion-padding-x: 1.5rem;
$accordion-button-padding-y: .85rem;
$accordion-button-focus-box-shadow: none;
// $accordion-button-padding-x:              $accordion-padding-x;


$modal-header-border-color: $primary;
$modal-inner-padding: 1rem;

$font-weight-bold: 500;
$font-weight-bolder: 700;

$dropdown-link-hover-bg: transparent;
$dropdown-link-hover-color: $navy;