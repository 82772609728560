.blink {
    -webkit-animation: blink-1 0.6s both;
    animation: blink-1 0.6s both
}

@-webkit-keyframes blink-1 {
    0%,50%,100% {
        opacity: 1
    }

    25%,75% {
        opacity: 0
    }
}

@keyframes blink-1 {
    0%,50%,100% {
        opacity: 1
    }

    25%,75% {
        opacity: 0
    }
}

/* ----------------------------------------------
 * Generated by Animista on 2022-5-31 13:10:58
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation bounce-bottom
 * ----------------------------------------
 */
 @-webkit-keyframes bounce-bottom {
    0% {
      -webkit-transform: translateY(45px);
              transform: translateY(45px);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
      opacity: 1;
    }
    24% {
      opacity: 1;
    }
    40% {
      -webkit-transform: translateY(24px);
              transform: translateY(24px);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    65% {
      -webkit-transform: translateY(12px);
              transform: translateY(12px);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    82% {
      -webkit-transform: translateY(6px);
              transform: translateY(6px);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    93% {
      -webkit-transform: translateY(4px);
              transform: translateY(4px);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    25%,
    55%,
    75%,
    87% {
      -webkit-transform: translateY(0px);
              transform: translateY(0px);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
    100% {
      -webkit-transform: translateY(0px);
              transform: translateY(0px);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
      opacity: 1;
    }
  }
  @keyframes bounce-bottom {
    0% {
      -webkit-transform: translateY(45px);
              transform: translateY(45px);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
      opacity: 1;
    }
    24% {
      opacity: 1;
    }
    40% {
      -webkit-transform: translateY(24px);
              transform: translateY(24px);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    65% {
      -webkit-transform: translateY(12px);
              transform: translateY(12px);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    82% {
      -webkit-transform: translateY(6px);
              transform: translateY(6px);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    93% {
      -webkit-transform: translateY(4px);
              transform: translateY(4px);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    25%,
    55%,
    75%,
    87% {
      -webkit-transform: translateY(0px);
              transform: translateY(0px);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
    100% {
      -webkit-transform: translateY(0px);
              transform: translateY(0px);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
      opacity: 1;
    }
  }

  .bounce-bottom {
	-webkit-animation: bounce-bottom 0.9s both;
	        animation: bounce-bottom 0.9s both;
}

* {
    transition: all .3s;
}

body {
    // padding-top: 93px;
    // background-color: #F4F4F4;
    background-color: #F8F8F8;
    // display: flex;
    // flex-direction: column;
    // min-height: 100vh;
}

.footer {
    margin-top: 3rem;
}

.navbar {
    border-bottom: 1px solid #eee;
}

a {
    text-decoration: none;
}

strong {
    font-weight: 500;
}

ul,
ol,
dl,
p {
    margin-bottom: 2rem;
}

p {
    +ul {
        margin-top: -1.5rem;
    }
}

ul {
    ul {
        list-style-type: disc;
    }
}

.mt-36 {
    margin-top: 36px;
}

.dropdown-toggle {
    &:after {
        border: none;
        content: "\e5cf";
        @extend .material-icons;
        font-size: 1rem;
        vertical-align: -0.255em;
        transition: all .3s;
    }

    &[aria-expanded="true"] {
        &:after {
            content: "\e5ce";
        }
    }
}

.text-14 {
    font-size: .875rem;
}

.text-navy {
    color: $navy;
}

.text-greydark {
    color: $grey-dark;
}

.bg-blue-light {
    background-color: $blue-light;
}

.gx-25 {
    --bs-gutter-x: 2.5rem;
}

.btn-icon {
    display: inline-flex;
    align-items: center;
    height: 3rem;
}

.page-simple {
    img {
        @extend .img-fluid;
        margin-bottom: 1rem;
    }
}

.mb-32 {
    margin-bottom: 2rem !important;
}

.btn {
    padding-bottom: .75rem;
}

.btn-primary {
    &[disabled],
    &.disabled,
    &:disabled {
        border-color: $grey;
        background-color: $grey;
    }
}

.btn-bluelight {
    color: $coal-black;
    border-color: $blue-light;
    background-color: $blue-light;
    padding: .5rem;
    font-weight: 400;
}

.btn-blue-light {
    border-color: $blue-light;
    background-color: $blue-light;
    color: $navy;
    font-weight: 500;
    @include hover-focus() {
        color: $navy;
        background-color: $white;
    }
}

.btn-outline-navy {
    color: $navy;
    border-color: $navy;
    background-color: #fff;
    @include hover-focus() {
        color: $white;
        background-color: $navy;
    }
}

.btn-outline-grey {
    color: $coal-black;
    border-color: $grey;

    [class^="material-"] {
        color: $navy;
    }

    @include hover-focus() {
        color: $navy;
        background-color: $grey;
        // [class^="material-"] {
        //     color: $white;
        // }
    }

}

.btn-grey {
    color: $white;
    border-color: $grey;
    background-color: $grey;
}

.lock-body-scroll {
    overflow: hidden !important;
}

.primary-block {
    background-color: $primary;
    color: $white;
    border-radius: $border-radius;
    box-shadow: 0px 4px 4px rgba(216, 216, 216, 0.12);
    margin-bottom: .5rem;
    padding: .75rem 1.5rem;

    h1,
    h2 {
        font-size: 1rem;
        line-height: 1.5;
        margin-bottom: 0;
        color: $white;
    }
}

.breadcrumb {
    margin-top: 1rem;
    margin-bottom: 2.5rem;
}

.breadcrumb-item {
    a {
        color: $grey-dark;
        text-decoration: none;

        @include hover-focus() {
            color: $primary;
        }
    }
}

.block-box {
    border-radius: $border-radius;
    padding: 1.5rem;
    margin-bottom: 1.5rem;
    box-shadow: 0px 4px 4px rgba(216, 216, 216, 0.12);
    background-color: $white;
    color: $grey-darker;
}

.g-24 {
    --bs-gutter-x: 1.5rem;
}

.py-col-12 {
    >* {
        padding-top: .75rem;
        padding-bottom: .75rem;
    }
}

.page-title {
    color: $navy;
    margin-bottom: 2rem;
}

.address-item {
    display: flex;
    text-decoration: none;
    margin-bottom: .75rem;
    color: $body-color;
    font-size: .875rem;

    &:last-child {
        margin-bottom: 0
    }

    span {
        color: $navy;
        margin-right: .5rem;
        font-size: 1.125rem;
    }

}

.card-title {
    @extend .h1;
    color: $black;
    margin-bottom: 1rem;
}

.card-footer {
    background-color: $white;
}

.section-title {
    @extend .h1;
    color: $navy;
    margin-bottom: 2rem;
}

.equal-cards {
    .card {
        height: 100%;
    }
}

.equal-box {
    .block-box {
        height: 100%;
    }
}

.p-mb-0 {
    p {
        margin-bottom: .5rem;
    }
}

.btn-navy {
    color: #fff;
    background-color: $navy;
    border-color: $navy;

    @include hover-focus() {
        background-color: darken($navy, 5%);
        color: #fff;
    }
}

.nav-tabs {
    border-bottom: 1px solid $grey-light;
    // flex-wrap: nowrap;
    // white-space: nowrap;
    // overflow-x: auto;
    // padding-bottom: 5px;

    .nav-link {
        border-radius: 0;

        @include media-breakpoint-up(xl) {
            padding: 1rem 3.5rem;
        }

        &.active {
            position: relative;

            &:after {
                content: "";
                height: 5px;
                width: 100%;
                background-color: $navy;
                position: absolute;
                left: 0;
                bottom: 0;
            }

            // border-bottom: 5px solid $navy;
            font-weight: 500;
        }
    }
}

.block-contact {
    // @include media-breakpoint-up(lg) {
    //     border-radius: $border-radius;
    //     padding: 1.5rem;
    //     margin-bottom: 1.5rem;
    //     box-shadow: 0px 4px 4px rgba(216, 216, 216, 0.12);
    //     background-color: $white;
    //     color: $grey-darker;

    //     .tab-content {
    //         padding-top: 1.5rem;
    //     }
    // }
    .accordion-button {
        background: #FFFFFF;
        color: $primary;

        &:after {
            position: absolute;
            top: 50%;
            right: 1.5rem;
            transform: translateY(-50%);
            content: "\e5ce";
            @extend .material-icons;
            // font-size: 1rem;
            vertical-align: -0.255em;
            transition: all .3s;

        }

        &[aria-expanded="false"] {
            &:after {
                content: "\e5cf";
            }
        }
    }

    .accordion-collapse {
        margin-top: 0;
    }

    .accordion-body {
        padding: 0;
    }

    .accordion-item {
        box-shadow: 0px 4px 4px rgba(216, 216, 216, 0.12);
    }
}


.employee {
    margin-left: 0;
    margin-right: 0;

    @include odd() {
        background: #FAFAFA;
    }

    @include even() {
        background: #fff;
    }

    a {
        color: $body-color;
        text-decoration: none;

        @include hover-focus() {
            color: $primary;
        }
    }

    >* {
        padding-top: .5rem;
        padding-bottom: .5rem;

        @include media-breakpoint-up(lg) {
            padding-top: 1rem;
            padding-bottom: 1rem;
        }
    }
}

.advice-item {
    height: 100%;

    p {
        margin-bottom: 0;
    }

    .card-footer {
        padding-top: 0;
        padding-bottom: 1.5rem;
    }

    a {
        text-decoration: none;
    }
}

.section-spacer {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
    @include media-breakpoint-up(xxl) {
        padding-top: 4rem;
        padding-bottom: 4rem;
    }
}

.pagination {
    .page-link {
        font-size: 1rem;
        border-radius: $border-radius;
        width: 2rem;
        height: 2rem;
        line-height: 2rem;
        text-align: center;
        border: none;
    }

    .material-icons {
        font-size: 18px;
        line-height: 2rem;
    }

    .page-item {
        +.page-item {
            margin-left: 1rem;
        }

        &:first-child {
            .page-link {
                border-radius: $border-radius;
            }
        }

        &:last-child {
            .page-link {
                border-radius: $border-radius;
            }
        }
    }
}

.modal-header {
    background-color: $primary;
}

.modal-title {
    display: flex;
    color: #fff;
    font-weight: 400;
    font-size: 1rem;

    span {
        margin-right: 1rem;
    }
}

.modal-footer {
    padding: 2rem 1rem;

    .row {
        width: calc(100% + 2rem);
        --bs-gutter-x: 2rem;
        margin: 0 calc(var(--bs-gutter-x) / -2);
    }

    .col-lg-6 {
        @include media-breakpoint-down(lg) {
            margin-top: .5rem;
        }

        button {
            width: 100%;
        }
    }
}

.modal-xl {
    .modal-header {
        background-color: #fff;
        border-color: #fff;
    }

    .modal-body {
        padding: 1.5rem;

        @include media-breakpoint-up(lg) {
            padding: 3.5rem;
        }
    }
}

.link-pdf {
    // display: flex;
    color: $grey-darker;
    white-space: nowrap;

    span {
        margin-right: .5rem;
        color: $grey;
        vertical-align: bottom;
    }
}

.mobile-toedges {
    @include media-breakpoint-down(sm) {
        margin-right: calc(var(--bs-gutter-x) / -1);
        margin-left: calc(var(--bs-gutter-x) / -1);
    }
}

.px-0-sm {
    @include media-breakpoint-down(sm) {
        padding-left: 0;
        padding-right: 0;
    }
}

.swiper {
    width: 100%;
    height: 100%;

    .swiper-slide {
        display: flex;
        justify-content: center;
        align-items: center;
        height: auto;

        .product {
            height: 100%;
            width: 100%;
        }
    }
}

.swiper-nav-outside {
    position: relative;
    --swiper-navigation-size: 1rem;
    --swiper-navigation-color: $grey-dark;

    .swiper-button-next {
        right: -1.25rem;
    }

    .swiper-button-prev {
        left: -1.25rem;
    }
}


.offset-left {
    @include media-breakpoint-up(lg) {
        margin-left: calc((100vw - #{map-get($container-max-widths, "lg")})/ 2);
    }

    @include media-breakpoint-up(xl) {
        margin-left: calc((100vw - #{map-get($container-max-widths, "xl")})/ 2);
    }

    @include media-breakpoint-up(xxl) {
        margin-left: calc((100vw - #{map-get($container-max-widths, "xxl")})/ 2);
    }

    @include media-breakpoint-up(xxxl) {
        margin-left: calc((100vw - #{map-get($container-max-widths, "xxxl")})/ 2);
    }
}

.heading-primary {
    color: $coal-black;
    text-align: center;
    margin-bottom: 1em;
}

// .notification {
//     display: none;
// }

.banner-item {
    display: block;
    @include media-breakpoint-down(lg) {
        margin-bottom: 1.5rem;
    }

    img {
        @extend .img-fluid;
        border-radius: $border-radius;
        width: 100%;
    }

    @include hover-focus() {
        opacity: .8;
    }
}

.tools-map {
    width: 100%;
    height: 411px;
    border-radius: $border-radius;
}

#product_comparison {
    background-color: $white;
    margin-bottom: 0;
        h4 {
        a {
            color: $coal-black;
        }
        color: $coal-black;
        margin-bottom: 0;
    }
    th {
        font-weight: 500;
        text-align: left;
        font-size: 18px;
        white-space: nowrap;
        border-bottom: 1px solid rgb(222, 226, 230);
        @include media-breakpoint-up(lg) {
            width: 300px;
        }
    }
    tr {
        th,
        td {
            min-height: 72px;
            padding: 1.5rem .5rem;
            &:not(:last-child) {
                border-right: 1px solid rgb(222, 226, 230);
            }
        }

    }
    td {
        &:not(:last-child) {
            border-right: 1px solid rgb(222, 226, 230);
        }
        // @include media-breakpoint-down(sm) {
        //     width: calc(100% - 128px);
        // }
    }
    tr {
        &:last-child {
            th,
            td 
            {border-bottom: 1px solid transparent;}
        }
    }
    th:first-child,
    td:first-child {
    position: sticky;
    left: 0;
    background-color: $white;
    z-index: 3;
    }
    .price {
        font-size: 1rem;
        font-weight: normal;
        color: $grey-darker;
    }
    .btn-outline-navy,
    .btn-danger {
        display: block;
        margin-bottom: .5rem;
        @include media-breakpoint-up(sm) {
            width: 300px;
        }
    }
    .close {
        position: absolute;
        top: -.25rem;
        right: -.25rem;
        background: $grey;
        width: 1.25rem;
        height: 1.25rem;
        padding: 0;
        border-radius: 50%;
        z-index: 2;

        i {
            color: $white;
            font-size: 1rem;
            line-height: 1.15;
        }
    }
}

.btn-print,
.btn-clear-compare {
    color: $navy;
    .material-icons-outlined {
        font-size: 1.25rem;
    }
}

.report-bug,
.report-noinfo {
    display: none;
}

.btn-to-top {
    display: none;
    position: fixed; 
    bottom: 20px; 
    right: 30px; 
    z-index: 99; 
    border: none; 
    outline: none;
    background-color: $secondary; 
    color: #fff; 
    cursor: pointer;
    padding: .5rem;
    border-radius: 4px;
    font-size: 18px;
    line-height: 1;
    @include hover-focus() {
        background-color: darken($secondary, 10%); 
        color: #fff; 
    }
}
