@-webkit-keyframes fade-in-top {
    0% {
        -webkit-transform: translateY(-50px);
        transform: translateY(-50px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes fade-in-top {
    0% {
        -webkit-transform: translateY(-50px);
        transform: translateY(-50px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
}

.fade-in-top {
    -webkit-animation: fade-in-top 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    animation: fade-in-top 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

.site-header {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0,0,0);
    min-height: 98px;
    height: 137px;
    -webkit-backface-visibility: hidden;
	-webkit-perspective: 1000;
    z-index: 1021;
    position: relative;
    @include media-breakpoint-up(lg) {
        height: 146px;
    }
    @include media-breakpoint-down(lg) {
        height: 164px;
    }
    &.fade-in-top {
        // height: 98px;
        .top-bar {
            display: none;
        }

        .main-header {
            padding-top: 2rem;
            padding-bottom: 1rem;
            @include media-breakpoint-down(lg) {
                padding-top: 1rem;
            }
        }
    }
}

.header-right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: .875rem;

    [class^="material-"] {
        font-size: 1rem;
    }

    a {
        color: #fff;
        text-decoration: none;

        @include hover-focus() {
            color: rgba($white, .8);
        }
    }

    .btn-icon {
        padding: 0.5rem 1rem;
    }

    >.header-right-item {
        &:not(:last-child) {
            // border-right: 1px solid $grey-light;
            position: relative;

            &:after {
                content: "";
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
                height: 1.5rem;
                width: 1px;
                background-color: $white;
            }
        }
    }

    .form-switch {
        margin-bottom: 0;
        margin-top: .15rem;
    }
}


.form-switch {
    .form-check-input {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='rgb(255, 255, 255)'/%3E%3C/svg%3E");
        background-color: $grey-darker;

        &:checked {
            background-color: $warning;
        }

        &:focus {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='rgb(255, 255, 255)'/%3E%3C/svg%3E");
        }

    }
}

.top-bar {
    color: $white;
}

.btn-search {
    color: $white;
    background-color: $navy;
}

.nav-right {
    justify-content: flex-end;
    flex-wrap: nowrap;

    @include media-breakpoint-up(lg) {
        margin-right: -1rem;
    }

    .nav-link {
        display: flex;
        align-items: center;
        color: $white;
        font-size: .875rem;

        // [class^="material-"] {
        //     font-size: 1rem;
        // }
    }

    .dropdown-toggle {
        border-top-left-radius: $border-radius;
        border-top-right-radius: $border-radius;
        outline: none;
        @include media-breakpoint-up(xl) {
            pointer-events: none;
        }

        &.show {
            background-color: #fff;
            color: $primary;

            &:before {
                background-color: #fff;
                width: 100%;
                height: 5px;
                position: absolute;
                bottom: -4px;
                left: 0;
                content: "";
            }
        }

        &:after {
            display: none;
        }
    }

    .dropdown-item {
        display: flex;
        align-items: center;
        font-size: .875rem;
        padding: 0.5rem;

        [class^="material-"] {
            margin-right: .5rem;
            // font-size: 1.5rem;
        }
    }

    .client-search {
        padding: 0 .5rem;
    }

    @include media-breakpoint-up(lg) {
        .dropdown {
            &:hover {
                .dropdown-toggle {
                    background-color: #fff;
                    color: $primary;
                }

                .dropdown-menu {
                    display: block;
                }
            }
        }
    }

    .dropdown-menu {
        border-top-left-radius: 0;
        box-shadow: 4px 4px 4px rgba(216, 216, 216, 0.12);
        border-color: $white;
        min-width: 223px;
        z-index: 1021;
        margin-top: -1px;

        @include media-breakpoint-down(lg) {
            border-top-left-radius: $border-radius;
            border-top-right-radius: 0;
        }
    }

    .dropdown-menu-end {
        border-top-left-radius: $border-radius;
        border-top-right-radius: 0;

        @include media-breakpoint-up(lg) {
            right: 0;
            left: auto;
        }
    }

    .dropdown-menu-lg-start {
        @include media-breakpoint-up(lg) {
            border-top-left-radius: 0;
            border-top-right-radius: $border-radius;
            left: 0;
            right: auto;
        }
    }
}

.clientinfo-label {
    display: flex;
    align-items: center;
    font-size: .875rem;
    margin-bottom: .5rem;
    color: $coal-black;
}

.navbar-toggler {
    color: $white;

    i:after {
        // border: none;
        content: "\e896";
        @extend .material-icons;
        // font-size: 1rem;
        // vertical-align: -0.255em;
        transition: all .3s;
    }

    &[aria-expanded="true"] {
        i:after {
            content: "\e5cd";
        }
    }

    @include media-breakpoint-up(lg) {
        display: none;
    }

    @include hover-focus() {
        box-shadow: none;
    }
}

.right-wrapper {
    display: flex;
    justify-content: flex-end;

    @include media-breakpoint-down(lg) {
        padding: 0;
        padding-right: .5rem;
        height: 100%;
        align-items: center;

        .nav-link {
            padding: .5rem;
        }
    }
}

.navbar-brand {
    max-width: 100%;
    height: auto;

    @include media-breakpoint-down(sm) {
        svg {
            max-width: 68px;
        }
    }
}

.top-bar,
.nav-link-text {
    @include media-breakpoint-down(lg) {
        display: none;
        max-width: 155px;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    @include media-breakpoint-down(md) {
        max-width: 125px;
    }
    @include media-breakpoint-down(sm) {
        max-width: 75px;
    }
}
#myAccount {
    .nav-link-text {
        max-width: 155px;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.main-header {
    background-color: $primary;
    padding-bottom: 2rem;

    @include media-breakpoint-down(lg) {
        padding-top: 1rem;
        padding-bottom: 1rem;
    }

    .grid-mobile {
        @include media-breakpoint-down(lg) {
            display: grid;
            grid-template-columns: repeat(5, 1fr);
            grid-template-rows: repeat(2, 1fr);
            grid-column-gap: 0px;
            grid-row-gap: 0px;

            .logo-wrapper {
                grid-area: 1 / 1 / 2 / 2;
            }

            .right-wrapper {
                grid-area: 1 / 2 / 2 / 6;
            }

            .search-wrapper {
                grid-area: 2 / 1 / 3 / 6;
            }

        }
    }
}


.header-link-blockcart {
    position: relative;

    .cart-count {
        position: absolute;
        left: 100%;
        font-size: .75rem;
        top: 0;
        transform: translate(-50%, -50%);
        // color: $white;
        font-weight: 500;
        font-family: "DM Sans", sans-serif;
    }

    .dropdown-menu {
        min-width: 310px;
    }

    ul {
        @extend .list-unstyled;
        max-height: 210px;
        overflow-y: scroll;
        padding: 1rem;
        margin-bottom: 0;

        li {
            display: flex;
            padding: 1rem 0;

            &:not(:last-child) {
                border-bottom: 1px solid $grey-light;
            }

            &:first-child {
                padding-top: 0
            }

            .img_content {
                @extend .col-3;
            }

            .right_block {
                @extend .col-9;
                padding-left: 1rem;
            }
        }

        .product-name {
            font-size: .75rem;
            display: inline-block;
        }

        .product-info {
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: $coal-black;
            font-size: .75rem;
        }
    }

    .cart-subtotals {
        padding: 1rem;
        color: $coal-black;
        font-weight: 500;

        .value {
            margin-left: auto;
        }
    }

    .checkout {
        background-color: $white;
        padding: 1rem;
        padding-top: 0;
    }

    .btn-delete {
        float: right;
        padding: 0;

        span {
            font-size: 1.25rem;
            color: $grey;
        }
    }
}

.site-menu {
    z-index: 3;
    position: relative;

    @include media-breakpoint-down(lg) {

        // @extend .collapse;
        &:not(.show) {
            display: none;
        }

        .nav-panel__nav-links {
            .nav {
                flex-direction: column;
            }
        }

        .nav-panel {
            height: calc(100vh - 164px);
        }

        .container-lg {
            padding-left: 0;
            padding-right: 0;
        }

        display: none;
        // position: absolute;
        position: sticky;
        top: 164px;
        bottom: 0;
        left: 0;
        width: 100vw;
        height: calc(100vh - 164px);
        z-index: 11;
        overflow: hidden;

        &.show,
        &.is-visible {
            display: block;
        }

        &.is-visible.collapse:not(.show) {
            display: none;
        }

        .list-wrapper {
            height: 100%;
            // padding: 0 20px;
            overflow-y: auto;
            background-color: $white;

            &:nth-child(2),
            &:nth-child(3),
            &:nth-child(4) {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                transform: translateX(100%);
                backface-visibility: hidden;
                transition: transform 0.5s;
            }

            &:nth-child(2).is-visible,
            &:nth-child(3).is-visible,
            &:nth-child(4).is-visible {
                transform: none;
                z-index: 1000;

                .departments__body {
                    display: block;
                    padding-top: 0;
                    position: initial;
                    border: none;
                }

                .departments__submenu {
                    position: initial;
                    display: block;
                    visibility: visible;
                }
            }

            &:nth-child(1)>ul>li>.sub-menu,
            &:nth-child(2) .level-3,
            &:nth-child(3) .level-4 {
                display: none;
            }
        }
    }

}

.list-wrapper {
    @include media-breakpoint-up(lg) {
        // width: 100%;

        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4) {
            display: none;
        }
    }
}


.search-widget {
    position: relative;

    &.active {
        .search-dropdown {
            display: block;
            opacity: 1;
        }

        .delete-search-phrase {
            opacity: 1;
        }
    }

}

.search-dropdown {
    position: absolute;
    z-index: 1022;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(216, 216, 216, 0.12);
    border-radius: 0px 0px 4px 4px;
    left: 0;
    right: 0;
    display: none;
    opacity: 0;
    margin-top: -3px;
    transition: all .5s;
    width: 100%;
    padding: 1.5rem .75rem;
    overflow-y: auto;
    max-height: calc(100vh - 164px);

    @include media-breakpoint-up(lg) {
        width: calc(100% + 300px);
    }

    .col-md-8 {
        overflow-y: auto;
        max-height: 350px;
        @include media-breakpoint-up(lg) {
            border-right: 1px solid $grey-light;
        }

        @include media-breakpoint-down(lg) {
            margin-bottom: 1.5rem;
        }
    }

    mark {
        background-color: transparent;
        font-weight: 500;
        color: $coal-black;
    }
    .product-image {
        max-height: 100px;
        object-fit: contain;
    }
}
.dropdown-cart {
    .product-image {
        max-height: 70px;
        object-fit: contain;
    }
}

.product-item {
    display: flex;
    align-items: flex-start;

    &:not(:last-child) {
        margin-bottom: 1.5rem;
    }

    .product-title {
        color: $grey-dark;

        @include hover-focus() {
            color: $navy;
        }
    }

    .spinner-group {
        width: 100px;
        height: 2rem;

        .btn {
            font-size: 1rem;
        }
    }

    .add-package,
    .btn-add-to-cart {
        width: initial;
        white-space: nowrap;
        padding: 0.25rem 0.5rem;
        height: 2rem;
    }

    .btn-add-to-cart {
        @include media-breakpoint-down(sm) {
            width: 100%;
        }
    }

    .product-data,
    .product-no {
        font-size: .75rem;
    }

    .product-datas {
        margin-top: .5rem;
    }

    .price {
        font-size: 1.125rem;
        color: $coal-black;
    }

    .text-danger {
        font-size: .875rem;
    }
}

.search-line {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    gap: 0 1rem;

    @include media-breakpoint-down(md) {
        gap: .5rem;
    }
    form {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        width: 100%;
        gap: 0 1rem;
    
        @include media-breakpoint-down(md) {
            gap: .5rem;
        }  
    }
}

.brand-link,
.category-link {
    display: block;
    color: $grey-dark;
    font-size: .875rem;
}

.search-block {
    h6 {
        font-size: .875rem;
    }

    &:not(:last-child) {
        margin-bottom: 1.5rem;
    }

    @include media-breakpoint-down(md) {
        border-top: 1px solid $grey-light;
        padding-top: 1rem;
    }
}

.delete-search-phrase {
    position: absolute;
    z-index: 5;
    color: $grey;
    right: 3rem;
    top: 50%;
    transform: translateY(-50%);
    opacity: 0;

    &:after {
        border: none;
        content: "\e5c9";
        @extend .material-icons-outlined;
        font-size: 1rem;
        vertical-align: -0.255em;
        transition: all .3s;
        padding: 1rem;
    }
}

.js-top-menu-bottom {
    display: flex;
    align-items: center;
    border-top: 1px solid $grey-light;
    border-bottom: 1px solid $grey-light;
    min-height: 56px;

    >* {
        width: 50%;
        // border: 1px solid $grey-light;
    }

    @include media-breakpoint-up(lg) {
        display: none;
    }


}

#_mobile_pvm_selector {

    .form-switch {
        width: min-content;
        margin: 0 auto;
    }
}

#_mobile_lang_selector {
    border-left: 1px solid $grey-light;

    .dropdown {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .dropdown-menu {
        display: flex;
        background-color: transparent;
        position: relative !important;
        transform: translate3d(0, 0, 0) !important;
    }

    .dropdown-toggle {
        padding: .15rem .25rem;
        border: 1px solid $navy;
        border-radius: $border-radius;

        &:after {
            display: none;
        }
    }

    .dropdown-item {
        padding: .25rem .5rem;
    }
}