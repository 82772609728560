.cart-item {
    position: relative;
    color: $coal-black;
    align-items: stretch;

    >[class*="col-"] {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .remove-from-cart {
        position: absolute;
        top: -.25rem;
        right: -.25rem;
        background: $grey;
        width: 1.25rem;
        height: 1.25rem;
        padding: 0;
        border-radius: 50%;

        i {
            color: $white;
            font-size: 1rem;
            line-height: 1.25;
        }
    }

    .product-title {
        color: $coal-black;
    }

    .product-no {
        display: block;
    }

    .btn-primary {
        padding: 0.813rem 1rem;
    }

    >[class*="col-"] {
        &:last-child {
            font-weight: 500;
            color: $coal-black;
        }

        @include media-breakpoint-down(xl) {
            margin: 0;
            padding: .5rem;
            max-width: initial;

            // &:not(:last-child) {
            //     // border-right: 1px solid $grey-light;
            // }
            // &:nth-child(2) {
            //     border-right: 1px solid transparent;
            // }
            .spinner-group {
                max-width: initial;
                // min-width: 122px;
            }

            &:not(:last-child) {
                border-right: 1px solid $grey-light;
                border-bottom: 1px solid transparent;
            }

            &:nth-child(2) {
                border-right: 1px solid transparent;
            }
        }

        @include media-breakpoint-down(sm) {
            &:not(:last-child) {
                border-right: 1px solid transparent;
            }

            // &:nth-child(2) {
            //     border-right: 1px solid transparent;
            // }
        }
    }
}

.order-item {
    >[class*="col-"] {
        @include media-breakpoint-down(xl) {
            margin: 0;
            padding: .5rem;
            max-width: initial;
            &:not(:last-child) {
                border-right: 1px solid $grey-light;
                border-bottom: 1px solid transparent;
            }

            &:first-child {
                border-right: 1px solid transparent;
            }
        }
        @include media-breakpoint-down(sm) {
            &:not(:last-child) {
                border-right: 1px solid transparent;
            }
        }
    }
}

.order-item-block,
.cart-item-block {
    display: flex;
    text-align: left;
    align-items: flex-start;

    img {
        margin-right: .5rem;
        max-height: 70px;
        object-fit: contain;
    }
}

.cart-summary-line {
    display: flex;
    justify-content: space-between;
    font-size: .875rem;
    padding: .75rem 0;
    // &:not(:last-child) {
    border-bottom: 1px solid $grey-light;

    // }
    &.cart-total {
        font-weight: 700;
        border-bottom: none;
        color: $coal-black;

        .value {
            color: $primary;
        }
    }
}

.cart-detailed-actions {
    .btn {
        margin-top: 1rem;
    }
}

.cart-detailed-total {
    .primary-block {
        margin-bottom: 0;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    .block-box {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        padding-top: .75rem;
    }

    .form-check-label {
        font-size: .875rem;
    }

    // @include media-breakpoint-down(sm) {
    //     // padding: 1rem;
    //     position: sticky;
    //     bottom: 0;
    //     z-index: 1020;
    //     margin-right: -1.25rem;
    //     margin-left: -1.25rem;
    //     .block-box {
    //         margin-bottom: 0;
    //     }
    // }
}

.page-checkout,
.page-cart {
    position: relative;
}

.cart-summary-totals {
    margin-bottom: 1rem;
}

.flex-justify-between-align-center {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.btn-back {
    color: $navy;
    font-weight: 700;
    // font-size: 14px;
}

.checkout-step {
    .row-head {
        --bs-gutter-x: 1rem;
        padding-left: 1.5rem;

        >*:first-child {
            text-align: left;
            align-items: flex-start;
        }

        >[class*="col-"] {
            padding: .594rem .4rem;
        }
    }
}

.delivery-radio {
    min-height: 48px;
    display: flex;
    padding-left: 2.5rem;
    padding-right: 0;
    margin-bottom: .5rem;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(216, 216, 216, 0.12);
    border-radius: $border-radius;
    text-align: center;

    @include media-breakpoint-up(lg) {
        align-items: center;

        .form-check-input {
            margin-top: 0;
        }
    }

    @include media-breakpoint-down(lg) {
        padding-top: .5rem;
        padding-bottom: .5rem;
    }

    .form-check-label {
        width: 100%;
        font-size: .875rem;
        color: $coal-black;
    }

    h4 {
        font-size: .875rem;
        color: $coal-black;
        font-weight: 400;
        margin-bottom: 0;
    }

    .row {
        --bs-gutter-x: 1rem;
        align-items: stretch;
    }

    [class*="col-"] {
        padding: .25rem 1rem;

        @include media-breakpoint-up(lg) {
            margin: .75rem 0;
            padding: .594rem .4rem;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            &:not(.order-lg-last) {
                border-right: 1px solid $grey;
            }
        }
    }

    .col-lg {
        text-align: left;

        @include media-breakpoint-up(lg) {
            align-items: flex-start;
        }
    }
}

@include media-breakpoint-down(lg) {
    .delivery-price {
        text-align: right;
    }

    .delivery-time,
    .delivery-date {
        display: flex;
        justify-content: space-between;

        span {
            font-weight: 500;
        }
    }
}

.checkout-step {
    .form-label {
        font-size: .875rem;
    }

    .form-text {
        font-size: .75rem;
    }
}

.pickup-check-label {
    font-size: .875rem;
}

.payment-options {
    .form-check-label {
        // display: flex;
        // flex-direction: column;
        // justify-content: center;
        // align-items: center;

        h4 {
            font-size: .75rem;
            color: $coal-black;
            font-weight: 400;
            margin-top: 1rem;
        }
    }

    .form-check-input {
        display: none;
    }

    .form-check {
        text-align: center;
        padding-left: 0;
        // border: 1px solid $grey-light;
        // border-radius: $border-radius;
        // padding: 1.5rem;
        // min-height: 126px;
        // height: 100%;

        // &.selected {
        //     border-color: $primary;
        // }

        // @include hover-focus() {
        //     opacity: .9;
        // }
    }

    .form-check-label {
        border: 1px solid $grey-light;
        border-radius: $border-radius;
        padding: 1.5rem;
        min-height: 150px;
        height: 100%;
        width: 100%;

        &.selected {
            border-color: $primary;
        }

        @include hover-focus() {
            opacity: .9;
        }
    }

    [class*="col-"] {
        margin-bottom: 1.5rem;
    }

    svg {
        max-width: 100%;
        height: auto;
    }
}