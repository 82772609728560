@import "fonts";
@import "variables";
@import "mixins";
@import "material/material-icons";
@import "node_modules/bootstrap/scss/bootstrap";
@import "global";
@import "footer";
@import "header";
@import "menu";
@import "login";
@import "account";
@import "forms";
@import "cart";
@import "timeline";
@import "products";
@import "filters";
@import "category";
@import "nouislider/nouislider";
@import "intlTelInput";
@import "swiper/swiper-bundle";
@import "select2/select2";
@import "select2/select2-bootstrap5";
// @import "fancybox/Fancybox";
@import 'node_modules/@fancyapps/ui/src/Fancybox/Fancybox.scss';


:root {
    --swiper-navigation-size: 24px;
    /*
  --swiper-navigation-color: var(--swiper-theme-color);
  */
  }
