.nav-account {
    .nav-link {
        display: flex;
        align-self: center;
        font-weight: 500;
        color: $grey-darker;
        background-color: $white;
        padding: .75rem .5rem;
        border-radius: $border-radius;
        border-left: 4px solid transparent;

        &.active {
            // color: $primary;
            background-color: $blue-light;
            border-left: 4px solid $primary;
        }

        @include hover-focus() {
            background-color: $blue-light;
            border-left: 4px solid $primary;
        }
    }

    .nav-item {
        margin-bottom: 2px;
    }
}

.block-bonus {
    border: 1px solid $grey-light;
    border-radius: $border-radius;

    @include media-breakpoint-up(md) {
        max-width: 616px;
    }

    // @include media-breakpoint-up(xl) {
    //     max-width: 60%;
    // }
    h4 {
        line-height: 1;
    }

    .row {
        align-items: center;
    }
}

.form-bonus {
    border-top: 1px solid $grey-light;

    [type="submit"] {
        width: 100%;
    }

    .row {
        >* {
            padding-top: .75rem;
            padding-bottom: .75rem;

            @include media-breakpoint-down(md) {
                padding-bottom: 0;

                &:last-child {
                    padding-bottom: .75rem;
                }
            }
        }
    }
}

.form-address {
    .row {
        --bs-gutter-x: 1.5rem;
    }

    label {
        font-size: .875rem;

        &:not(.form-check-label) {
            font-weight: 500;
        }
    }

    @include media-breakpoint-down(lg) {
        button {
            margin-top: 1rem;
        }

    }
}

.form-clients {
    label {
        font-size: .875rem;
        margin-bottom: .25rem;
    }

    .row {
        --bs-gutter-x: 1.5rem;

        >* {
            padding-bottom: 1.5rem;

            @include media-breakpoint-up(lg) {
                &:nth-last-child(-n+2) {
                    padding-bottom: 0;
                }
            }

            @include media-breakpoint-down(lg) {
                &:last-child() {
                    padding-bottom: 0;
                }
            }
        }
    }
}

.clients-table {
    .row {
        align-items: center;

        >[class*="col-"] {
            @include media-breakpoint-down(xl) {
                &:nth-last-child(-n+2) {
                    border-bottom: 0;
                }
            }
        }
    }

    .btn {
        width: 100%;
        margin-top: -.5rem;
        padding: 0.813rem 1rem;

        @include media-breakpoint-up(lg) {
            margin-bottom: -.5rem;
        }
    }
}

.lg-16 {
    [class^="col-"] {

        // @include media-breakpoint-down(xl) {
        //     width: auto;
        // }
        // @include media-breakpoint-up(lg) {
        @media (min-width: 992px) and (max-width: 1200px) {

            overflow-wrap: break-word;
            word-wrap: break-word;
            width: 16.66667%;

            .link-pdf {
                white-space: normal;
            }
        }
    }
}

.lg-20 {
    [class^="col-"] {
        @include media-breakpoint-up(lg) {
            overflow-wrap: break-word;
            word-wrap: break-word;
            width: 20%;
        }
    }

}


.xl-20 {
    [class^="col-"] {
        @include media-breakpoint-up(xl) {
            overflow-wrap: break-word;
            word-wrap: break-word;
            width: 20%;
        }
    }

}

.table-listitem {
    [class*="col-lg"] {
        @include media-breakpoint-up(lg) {
            overflow-wrap: break-word;
            word-wrap: break-word;

            // &:not([class*="col-xl"]) {
            //     width: 20%;
            // }

        }

        @include media-breakpoint-only(lg) {

            // width: 20%;
            &.col-xl-4 {
                order: -1;
                // flex: 1 0 100%;
                text-align: left;
                border-right: none !important;
                padding-bottom: 0;
                margin-bottom: 0;
            }

            .spinner-group {
                max-width: 100%;
            }
        }
    }

    .row {
        @include media-breakpoint-up(xl) {
            align-items: stretch;

            >[class*="col-"] {
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
        }

        >*:nth-last-child(2) {
            min-width: 160px;
            @include media-breakpoint-down(xl) {
                text-align: center!important;
            }
        }

        @include media-breakpoint-down(xl) {
            border-top: 2px solid $primary;
        }

        @include media-breakpoint-down(lg) {
            justify-content: space-around;
            position: relative;
            margin: 0 -.75rem 1.5rem;

            >[class*="col-"] {
                text-align: left;
                margin: 0;
                padding: 1rem .25rem;

                // &.col-xl-4 {
                //     padding-bottom: 27px;
                // }
                &:not(:nth-last-child(-n+2)) {
                    border-bottom: none;
                }
            }

            .spinner-group {
                // max-width: 100%;
                max-width: 112px;
                margin: 0 auto;
            }

            // .product-datas {
            //     position: absolute;
            //     left: .25rem;
            // }
        }

        @include media-breakpoint-between(md, xl) {
            >[class*="col-"] {
                padding: .25rem;
            }

            .btn {
                padding: 0.375rem 0.75rem;
            }

            .product-data {
                margin-bottom: 0;

            }

            .product-datas {
                white-space: nowrap;
            }

            // }
            // @include media-breakpoint-between(lg, xl) {
            // h4,
            // .product-datas {
            //     display: inline-block;
            // }
            .col-xl-4 {
                display: flex;
                justify-content: space-between;
            }

            .btn-bluelight,
            .btn-navy {
                padding: .5rem;
                height: 40px;
            }

            .btn-navy {
                display: block;
            }
        }
    }

    [data-legend] {
        &:before {
            display: none;
        }
    }
}

.debt-info {
    color: #000;

    .row {
        margin: 0;
        --bs-gutter-x: 1rem;

        @include odd() {
            background-color: #F8F8F8;

            &.red {
                background: rgba(232, 0, 39, 0.1);
            }
        }

        >* {
            padding-top: .25rem;
            padding-bottom: .25rem;
        }

        >*:last-child {
            text-align: right;
        }
    }

    .red {
        background: rgba(232, 0, 39, 0.15);
        color: $danger;
    }
}

// .accordion-body {
//     margin: .5rem 0;
// }
.accordion-collapse {
    // margin: .5rem 0;
    margin-top: .5rem;
    background-color: #fff;
    border-radius: $border-radius;
}

.accordion-item {
    margin-bottom: .5rem;
}

.accordion-button {
    font-weight: 500;
    border-radius: $border-radius;
    height: 3rem;
}

.form-account {
    .row {
        --bs-gutter-x: 1.5rem;
    }

    label {
        font-weight: 500;
        font-size: .875rem;
        color: $grey-darker;
    }
}

.category-item {
    background-color: #fff;
    border-radius: $border-radius;
    // padding: 1rem 1.5rem;
    padding: 1rem 1rem;
    color: $grey-darker;
    display: flex;
    align-items: center;
    height: 100%;
    position: relative;
    font-size: .875rem;

    @include media-breakpoint-down(md) {
        padding: .5rem;
    }

    img {
        margin-right: 1rem;
        max-width: 24px;
        height: auto;
    }

    .purchase-status {
        position: absolute;
        right: .25rem;
        bottom: .5rem;
    }
}

.read-message {
    color: $grey-darker;
    text-decoration: none;

    @include hover-focus() {
        color: $primary;
    }

    &:after {
        display: inline-block;
        width: .75rem;
        height: .75rem;
        margin-left: .5rem;
        content: "";
        background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='currentcolor'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");
        background-repeat: no-repeat;
        background-size: .75rem;
        transition: transform .2s ease-in-out;
    }

    &[aria-expanded="true"] {
        &:after {
            transform: rotate(-180deg);
            background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='currentcolor'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");
        }
    }
}

.account-right {
    position: relative;
}

.branch-select {
    display: flex;
    align-items: center;

    @include media-breakpoint-up(lg) {
        position: absolute;
        bottom: 100%;
        right: .75rem;

    }
}

.address-card {
    border-color: $grey-light;
    font-size: .75rem;
    height: 100%;

    p {
        margin-bottom: 0;
    }

    .card-body {
        padding: 0 .5rem .5rem;
    }

    .card-header {
        display: flex;
        background-color: transparent;
        font-size: .875rem;
        padding: .5rem;
        color: $coal-black;

        [class^="material-"] {
            color: $grey-dark;
        }
    }

    .form-check-input {
        position: absolute;
        top: 0.75rem;
        left: 0.75rem;
        margin-top: 0;
    }

    .form-check-label {
        .card-header {
            margin-left: 1.5rem;
        }
    }
}

.iti.iti--allow-dropdown {
    display: block;
}

.order-details,
.customer-address {
    p {
        margin-bottom: 0;
    }
}

.order-details {
    strong {
        color: $primary;
    }
}

.order-details-total-line {
    display: flex;
    justify-content: space-between;
    padding: 0.75rem 0;
    font-size: .875rem;

    &:not(:last-child) {
        border-bottom: 1px solid $grey-light;
    }

    &:last-child {
        font-size: 1.5rem;
        color: $coal-black;
    }
}