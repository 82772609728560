.copyright-block {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    padding: 1rem 0;
    margin-top: 2rem;

    .nav-link {
        font-size: .75rem;
        color: $white;
        @include hover-focus() {
            text-decoration: underline;
        }
    }

    @include media-breakpoint-down(md) {
        .nav-item {
            width: 100%;
        }
    }

    // .nav-item {
    //     &:not(:last-child) {
    //         border-right: 1px solid rgba($white, .3);
    //     }
    // }
}

.nav-signature {
    @include media-breakpoint-up(md) {
        justify-content: flex-end;
    }

    .nav-item {
        display: flex;
        font-size: .75rem;
        align-items: center;
        margin-left: 1rem;
    }

    .nav-link {
        font-weight: 700;
        padding: .235rem 0;
        color: $white;
        @include hover-focus() {
            text-decoration: underline;
        }
    }
}

.footer-main {

    color: $white;
    h2, h3, h4, a {
        color: $white;
    }
    h4 {
        font-size: 1rem;
        font-weight: 700;
    }
    .nav {
        margin-left: -1rem;

        &.flex-column {
            margin-bottom: 2.125rem;
        }
    }
    .flex-column {
        .nav-link {
            padding-top: .25rem;
            padding-bottom: .25rem;
            font-size: .875rem;
            @include hover-focus() {
                text-decoration: underline;
            }
        }
    }

}

.site-footer-top {
    padding-top: 6.25rem;

    // @include media-breakpoint-down(md) {
    //     padding-top: 5rem;
    // }

}

.link-contact {
    display: flex;
    text-decoration: none;
    font-size: .875rem;
    margin-bottom: 0;
    padding: .25rem 0;

    .material-icons {
        margin-right: 1rem;
        font-size: $font-size-base;
    }
}

.btn-social {
    padding: .5rem;
    margin: .5rem 0;
    color: $white;
    @include hover-focus() {
        box-shadow: none;
        svg {
            path {
                fill: $white;
            }
        }
    }
    svg {
        width: 1rem;
        height: 1rem;
        path {
            fill: $white;
        }
    }
    // &:first-child {
    //     margin-left: -1.5rem;
    // }
}

.contact-block {
    > :nth-child(4) {
        &.btn-social {
            margin-left: -.5rem;
        }
    }
}

.form-newsletter {
    h4 {
        font-weight: 500;
        // font-size: 1rem;
        line-height: 1.5;
        margin-bottom: 1rem;
    }
    .form-check-label {
        font-size: .75rem;
    }
    .btn {
        line-height: 1;
    }
}