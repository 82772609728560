$thumbnail-size: 250px;
$thumbnail-height: 400px;
$product-description-height: 70px;

.product-miniature {

    .product-availability,
    .product-datas,
    .product-price,
    .block-add {
        // margin: 1rem 0;
        margin-top: 1rem;
    }

    .brand-img {
        // position: absolute;
        // right: 1rem;
        // top: 1rem;
        // z-index: 2;
        margin-left: auto;
        display: block;
        margin-top: 0.875rem;
        margin-right: 1rem;
    }

    height: 100%;
    border-radius: $border-radius;

    .product-description {
        padding: 1rem 1.5rem;
    }

    .product-cat {
        a {
            color: $body-color;
            text-decoration: none;
            font-size: 12px;
        }
    }

    .product-title {
        margin-top: 0.5rem;

        @include media-breakpoint-up(md) {
            min-height: 3.5em;
        }

        a {
            // font-size: .938rem;
            // font-weight: 600;
            color: $coal-black;
            text-decoration: none;
        }
    }

    // .thumbnail-container {
    position: relative;
    height: auto;
    // overflow: hidden;
    background: $white;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .product-thumbnail {
        position: relative;
        display: block;
        // height: calc(100% - #{$product-description-height});
        height: 185px;

        img {
            position: relative;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            max-width: 100%;
            max-height: 100%;
            margin: auto;
            height: 185px;
            width: 100%;
            object-fit: contain;

        }
    }

    // }
}

.price {
    @extend .h2;
}
.price-contract {
    @extend .text-success;
}
.regular-price {
    color: $grey-darker;
    text-decoration-line: line-through;

    &:before {
        content: "/";
        display: inline-block;
        margin: 0 .25rem;
        color: $grey-light;
        // font-size: 30px;
    }
}

.product-flags {
    padding-left: 0;
    list-style: none;
    position: absolute;
    top: .75rem;
    left: 1rem;
    z-index: 2;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    // width: 100%;
    pointer-events: none;
    font-style: normal;
    font-weight: 400;
    font-size: .875rem;

    .product-flag {
        width: fit-content;
        padding: .25rem 0.875rem;
        margin-top: .25rem;
        color: $white;
        pointer-events: auto;
        background: $primary;
        display: inline-flex;
        align-items: center;
        border-radius: $border-radius;
        position: relative;
        height: 2rem;

        &.discount-percentage,
        &.discount-amount,
        &.discount {
            background-color: $danger;
        }

        &.new {
            background-color: $success;
        }
    }

    span[class^="material"] {
        font-size: 1rem;
    }

    svg {
        margin-right: .25rem;
    }
}

.product-data {
    background-color: $grey-lighter;
    border-radius: $border-radius;
    font-size: .875rem;
    color: $coal-black;
    padding: .25rem .5rem;
    text-transform: uppercase;
    white-space: nowrap;
    display: inline-block;
    // margin: .25rem;
    margin-right: .25rem;
    margin-bottom: .5rem;
}

.miniature-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 1.5rem;
    border-top: 1px solid $grey-lighter;
}

.purchase-status {
    border-radius: 50%;
    width: .625rem;
    min-width: .625rem;
    height: .625rem;
    min-height: .625rem;
    background-color: $grey;
    display: inline-block;
    margin-right: .25rem;

    &--grey {
        background-color: $grey;
    }

    &--success {
        background-color: $success;
    }

    &--warning {
        background-color: $warning;
    }

    &--danger {
        background-color: $danger;
    }
}

.purchase-statuses {
    font-size: .875rem;
    font-weight: 500;
    color: $grey-dark;
}

.btn-compare {
    font-weight: 500;
    color: $grey-darker;

    &.active {
        color: $navy;
    }

    .material-icons {
        font-size: 1rem;
    }

    &.btn-outline-navy {
        color: $navy;
        font-weight: 700;

        @include hover-focus() {
            color: $white;
        }

        &.active {
            @extend .btn-navy;
        }
    }
}

.btn-add-to-cart {
    width: 100%;
}

.miniature-delivery {
    font-size: .75rem;
    margin: .5rem 0;
    color: $coal-black;
}

.product-availability {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.block-add {
    .row {
        margin-bottom: 1rem;
        --bs-gutter-x: 1rem;
        align-items: center;
        // > * {
        //     height: 100%;
        //     .add-package {
        //         height: 100%;
        //     }
        // }
    }
}

.add-package {
    border: 1px solid $grey-light;
    box-sizing: border-box;
    border-radius: $border-radius;
    color: $navy;
    // padding: 0.813rem 1rem;
    width: 100%;
    justify-content: center;
    padding: .5rem;
    height: 3rem;
    white-space: nowrap;

    svg {
        margin-right: .25rem;
    }
}

.date {
    white-space: nowrap;
}

.product-container {
    position: relative;

    // @include media-breakpoint-up(xl) {
    .price {
        font-size: 2rem;
        font-weight: 700;
    }

    .regular-price {
        font-size: 1.625rem;
    }

    // }

    h1 {
        color: $coal-black;

        @include media-breakpoint-down(xl) {
            font-size: 1.75rem;
        }
    }
}

.stock-status {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    display: inline-block;
}

.block-stock {
    .d-flex {
        align-items: center;
        justify-content: space-between;
    }
}

.product-data-statuses {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    border-top: 1px solid #EDEDED;
    padding-top: 1rem;
    margin-bottom: 1.5rem;

    .purchase-statuses {
        font-weight: 400;
        margin-right: 1rem;
    }

    .product-datas {
        margin-top: .5rem;
    }
}

.product-extra-btn {
    margin-bottom: 1rem;

    >* {
        &:not(:last-child) {
            margin-right: 1rem;
        }
    }

    .btn {

        min-width: 182px;
        justify-content: center;
        margin-bottom: .5rem;

        //    @include media-breakpoint-down(sm) {
        //        width: 100%;
        //    }
        @media (max-width: 434px) {
            width: 100%;
        }
    }
}

.add-to-cart {
    margin-bottom: 1rem;

    p {
        margin-bottom: 0;
    }

    .btn {
        &:not(.btn-spinner) {
            max-width: 184px;

            @include media-breakpoint-down(sm) {
                width: calc(50% - 1rem);
            }
        }
    }

    .spinner-group {
        @include media-breakpoint-up(sm) {
            max-width: 122px;
        }

        @include media-breakpoint-down(sm) {
            width: calc(50% - 1rem);
        }

        margin-right: 1rem;
    }

    .add-package {
        margin-right: 1rem;
        width: 122px;
    }
}

.collapse-down {
    &:after {
        border: none;
        content: "\e5cf";
        @extend .material-icons;
        font-size: 1rem;
        vertical-align: -0.255em;
        transition: all .3s;
    }

    &[aria-expanded="true"] {
        &:after {
            content: "\e5ce";
        }
    }
}

.block-add-to-cart {
    >* {
        margin-bottom: 1rem;
    }
}

.data-sheet {
    .d-flex {
        align-items: center;
        justify-content: space-between;

        &:not(:last-child) {
            border-bottom: 1px solid $grey-light;
        }
    }

    .value,
    .name {
        padding: .5rem 0;
    }

    .value {
        color: $coal-black;
        font-weight: 500;
        text-align: right;
        padding-left: .5rem;
    }
}

.collapse-body {
    border: 1px solid $grey-light;
    box-shadow: 0px 4px 4px rgba(216, 216, 216, 0.12);
    border-radius: 0px 0px 4px 4px;
    padding: 0.5rem;
    padding-top: 1rem;
    margin-top: -.5rem;
}

.product-extra {
    .collapse-down {
        font-size: 1.125rem;
        text-align: left;
        position: relative;
        font-weight: 500;

        &::after {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 1.5rem;
            font-size: 1.5rem;
        }
    }
}

.similar-product {
    border: 1px solid $grey-light;
    border-radius: $border-radius;
    margin-bottom: 1.5rem;
    padding: 1rem;

    .row {
        align-items: center;
    }

    h4 {
        font-size: .875rem;
        color: $coal-black;
        font-weight: 400;
    }

    img {
        margin-right: .5rem;
    }
}

.similar-product-price {
    color: $coal-black;
    font-weight: 500;
}

.discount-info {
    color: $coal-black;
    padding: 0 .5rem;

    .material-icons {
        color: $grey-dark;
        font-size: 1rem;
    }

    &:not(:last-child) {
        // border-right: 1px solid $grey-light;
        position: relative;

        &:after {
            content: "";
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            height: 1.5rem;
            width: 1px;
            background-color: $grey;
        }
    }
}

.product-information {
    h3 {
        font-weight: 400;
    }
}

.reserved-qty {
    font-weight: 500;
    color: $coal-black;
}

.fancybox__thumb {
    opacity: 0.4;
}

.fancybox__thumb:hover,
.is-nav-selected .fancybox__thumb {
    opacity: 1;
}

.is-nav-selected .fancybox__thumb::after {
    display: none;
}


// .fancybox__slide {
//     opacity: 0;
//     -moz-transition: all 0.01s ease;
//     -o-transition: all 0.01s ease;
//     -webkit-transition: all 0.01s ease;
//     transition: all 0.01s ease;}
    
//     .fancybox__slide.is-selected {
//     opacity: 1!important;
//     -moz-transition: all 0.25s ease;
//     -o-transition: all 0.25s ease;
//     -webkit-transition: all 0.25s ease;
//     transition: all 0.25s ease;}