.row-head {
    align-items: stretch;
    height: 100%;
    background-color: $primary;
    color: $white;
    text-align: center;
    margin: 0 0 .5rem;
    box-shadow: 0px 4px 4px rgba(216, 216, 216, 0.12);
    border-radius: $border-radius;
    font-size: .875rem;
    // $point: 'lg';

    >[class*="col-"] {
        margin: .75rem 0;
        padding: .5rem 1rem;
        &:not(:last-child) {
            border-right: 1px solid $white;
        }
        @include media-breakpoint-up(lg) {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    }

    @include media-breakpoint-down(lg) {
        display: none;
    }

    &.row-head--xl {
        // $point: 'xl';
        >[class*="col-"] {
            @include media-breakpoint-up(xl) {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }
        }
        @include media-breakpoint-down(xl) {
            display: none;
        }
    }


}


.row-item {
    background-color: $white;
    color: $grey-darker;
    text-align: center;
    margin: 0 0 .5rem;
    box-shadow: 0px 4px 4px rgba(216, 216, 216, 0.12);
    border-radius: $border-radius;
    font-size: .875rem;

    @include media-breakpoint-down(lg) {                        
        padding: .5rem 0;
        margin-bottom: 1.5rem;
        .add-package {
            width: fit-content;
            margin-left: auto;
        }    
        .link-pdf {
            justify-content: flex-end;
        }
        [data-legend] {
            text-align: right;

            &:before {
                content: attr(data-legend);
                color: $navy;
                // margin-bottom: 1rem;
                width: 45%;
                font-size: 14px;
                // line-height: 17px;
                float: left;
                text-align: left;
                font-weight: 500;
            }
        }

    }
    >[class*="col-"] {
        @include media-breakpoint-up(lg) {
            margin: .75rem 0;
            padding: .594rem 1rem;

            &:not(:last-child) {
                border-right: 1px solid $grey;
            }
        }

        @include media-breakpoint-down(lg) {
            margin: 0 1.5rem;
            padding: 1rem 0;
            max-width: calc(100% - 3rem);
            // &:not(:last-child),
            &:not(:nth-last-child(-n+2))  {
                border-bottom: 1px solid $grey;
            }
        }
    }

    &.row-item--xxl {
        @include media-breakpoint-down(xxl) {                        
            padding: .5rem 0;
            margin-bottom: 1.5rem;
            .add-package {
                width: fit-content;
                margin-left: auto;
            }    
            .link-pdf {
                justify-content: flex-end;
            }
            [data-legend] {
                text-align: right;

                &:before {
                    content: attr(data-legend);
                    color: $navy;
                    // margin-bottom: 1rem;
                    width: 45%;
                    font-size: 14px;
                    // line-height: 17px;
                    float: left;
                    text-align: left;
                    font-weight: 500;
                }
            }
    
        }
        >[class*="col-"] {
            @include media-breakpoint-up(xxl) {
                margin: .75rem 0;
                padding: .594rem 1rem;
    
                &:not(:last-child) {
                    border-right: 1px solid $grey;
                }
            }
    
            @include media-breakpoint-down(xxl) {
                margin: 0 1.5rem;
                padding: 1rem 0;
                max-width: calc(100% - 3rem);
    
                // &:not(:last-child),
                &:not(:nth-last-child(-n+2))  {
                    border-bottom: 1px solid $grey;
                }
            }
        }
    }

    &.row-item--xl {
        @include media-breakpoint-down(xl) {                        
            padding: .5rem 0;
            margin-bottom: 1.5rem;
            .add-package {
                width: fit-content;
                margin-left: auto;
            }    
            .link-pdf {
                justify-content: flex-end;
            }
            [data-legend] {
                text-align: right;

                &:before {
                    content: attr(data-legend);
                    color: $navy;
                    // margin-bottom: 1rem;
                    width: 45%;
                    font-size: 14px;
                    // line-height: 17px;
                    float: left;
                    text-align: left;
                    font-weight: 500;
                }
            }
    
        }
        >[class*="col-"] {
            @include media-breakpoint-up(xl) {
                margin: .75rem 0;
                padding: .594rem 1rem;
    
                &:not(:last-child) {
                    border-right: 1px solid $grey;
                }
            }
    
            @include media-breakpoint-down(xl) {
                margin: 0 1.5rem;
                padding: 1rem 0;
                max-width: calc(100% - 3rem);
                &:not(:last-child) {
                    border-right: none;
                }
                // &:not(:last-child),
                &:not(:nth-last-child(-n+2))  {
                    border-bottom: 1px solid $grey;
                }
            }
        }
    }

    .add-package {
        padding: 0;    
        display: block;
        height: auto;
        border-color: transparent;
    }

    // >[class*="col-"] {
    //     @include media-breakpoint-up(--bpoint) {
    //         margin: .75rem 0;
    //         padding: .594rem 1rem;

    //         &:not(:last-child) {
    //             border-right: 1px solid $grey;
    //         }
    //     }

    //     @include media-breakpoint-down(--bpoint) {
    //         margin: 0 1.5rem;
    //         padding: 1rem 0;
    //         max-width: calc(100% - 3rem);

    //         &:not(:last-child) {
    //             border-bottom: 1px solid $grey;
    //         }
    //     }
    // }

    // @include media-breakpoint-down(--bpoint) {
    //     padding: .5rem 0;
    //     margin-bottom: 1.5rem;

    //     .link-pdf {
    //         justify-content: flex-end;
    //     }
    // }

    [class^="material-"] {
        color: $grey;
    }

    // [data-legend] {
    //     @include media-breakpoint-down(--bpoint) {
    //         text-align: right;

    //         &:before {
    //             content: attr(data-legend);
    //             color: $navy;
    //             // margin-bottom: 1rem;
    //             width: 45%;
    //             font-size: 14px;
    //             // line-height: 17px;
    //             float: left;
    //             text-align: left;
    //             font-weight: 500;
    //         }
    //     }
    // }

    &.row-item--narrow {
        >[class*="col-"] {
            @include media-breakpoint-up(lg) {
                padding: .5rem;
            }
        }
    }

}

.input-group {
    .btn.btn-spinner {
        padding: 0 .5rem;
    }
}

.btn-spinner {
    border: 1px solid $grey-light;
    background-color: $white;
    font-size: 1.5rem;
    font-weight: 400;

    &.btn-decrement {
        border-right: none;
    }

    &.btn-increment {
        border-left: none;
    }

    @include hover-focus() {
        color: $primary;
    }
}

.spinner-group {
    .spinner {
        border-right: none;
        border-left: none;
        padding: 0.782rem 0;
    }

    .input-group-text {
        padding: 0;
        padding-right: .5rem;
        background: transparent;
        border-right: none;
        line-height: 1;
    }
}

.input-group {
    .btn {
        padding: 0 .7rem;
    }
}

.form-pricelist {
    display: flex;
    flex-wrap: wrap;

    // border-radius: $border-radius;
    // border: 1px solid $white;
    @include media-breakpoint-down(md) {
        margin-right: -1.5rem;
        margin-left: -1.5rem;
    }

    .form-check {
        display: flex;
        align-items: center;
        padding: 1rem;
        padding-left: 2.5rem;
        background-color: $white;
        border: .5px solid $grey-light;
        margin-bottom: 0;
        // margin: -1px;
        @extend .col-6;
        @extend .col-xl-3;

    }

    .form-check-input {
        min-width: 1rem;

        @include hover-focus() {
            box-shadow: none;
        }
    }

    .form-check-label {
        display: flex;
        align-items: center;
        font-size: .875rem;

        img {
            margin-right: .75rem;
            margin-left: .75rem;
        }
    }

    .form-footer {
        @include media-breakpoint-down(md) {
            padding-left: 1.5rem;
            padding-right: 1.5rem;
        }
    }
}

.table-invoices {
    font-size: .875rem;

    .row-head,
    .row-item {
        >*:nth-child(3) {
            min-width: 150px;
        }
        @include media-breakpoint-up(xl)
       { align-items: stretch;}
    }
    // .row-head {
    //     @include media-breakpoint-down(xl) {
    //         display: none;
    //     }
    // }
    @include media-breakpoint-up(xl) {
        [class*="col-"] {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }

}

.checks-with-borders {
    display: flex;
    flex-wrap: wrap;

    .form-check {
        margin-right: 1rem;

        @include media-breakpoint-up(sm) {
            &:not(:last-child) {
                padding-right: 1rem;
                margin-right: 1rem;
                border-right: 1px solid $grey;
            }
        }
    }
}

.form-label {
    font-weight: 500;
    color: $coal-black;
}

.form-control:disabled, .form-control[readonly] {
    background-color: $white;
    color: $grey;
}
.spinner-group {
    .btn:disabled, 
    .btn.disabled {
        color: $grey;
    }
}

.btn-simple {
    padding: 0;
    font-weight: 400;
    color: $body-color;
    [class^="material-"] {
        color: $body-color;
    }
    @include hover-focus() {
        color: $primary;
        [class^="material-"] {
            color: $primary;
        }
    }
}