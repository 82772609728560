.products {
    --bs-gutter-x: 1.5rem;

    .product {
        margin-bottom: 1.5rem;
    }
}

.category-title {
    margin-bottom: 0;
}

.category-sort {
    font-size: .875rem;
    color: $grey-darker;

    .row {
        align-items: center;
    }

    .category-sort-right {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        // flex-wrap: wrap;
        >* {
            &:not(:last-child) {
                margin-right: 1rem;
            }
        }

        @include media-breakpoint-down(lg) {
            display: none;
        }
    }
}

.perpage,
.sortby {
    display: flex;
    align-items: center;

    // flex-wrap: wrap;
    label {
        margin-right: .5rem;
        white-space: nowrap;
    }
}

.btn-group>.btn {
    padding: .5rem;
    line-height: 1;

    .material-icons {
        font-size: 1.25rem;
    }
}

.swiper-product-cover {
    .swiper-slide {
        height: auto;

        img {
            // width: 100%;
            width: initial;
            height: 100%;
            object-fit: cover;
        }
    }
}

.product-cover {
    position: relative;
    margin-bottom: .5rem;

    img {
        background: $white;
    }

}

.product-images {
    padding-left: 0;
    overflow-x: auto;
    white-space: nowrap;

    >li.thumb-container {
        display: inline-block;
        margin-bottom: .5rem;
        margin-right: 0.5rem;
        border: 1px solid $grey-light;
        border-radius: $border-radius;
        background-color: $white;
        padding: .5rem;

        &.selected {
            border-color: $blue;
        }

        >.thumb {
            object-fit: cover;
            cursor: pointer;
            border-radius: $border-radius;
        }
    }
}

.row-item {
    h4 {
        font-size: .875rem;
        color: $coal-black;

        // text-align: left;
        a {
            color: $coal-black;
        }
    }

    .price {
        color: $coal-black;
        font-weight: 500;
        font-size: 1rem;
    }

    .btn-bluelight {
        font-size: .75rem;

        @include media-breakpoint-only(lg) {
            margin-left: auto;
        }
    }
    .btn-navy {
        @include media-breakpoint-only(lg) {
            margin-left: auto;
        }
    }

    .spinner-group {
        @include media-breakpoint-down(xl) {
            max-width: 50%;
            margin-left: auto;
        }
    }

    .product-title {
        a {
            color: $coal-black;
        }
    }
}

.price-old {
    text-decoration: line-through;
}

.category-footer {

    .pagination {
        @include media-breakpoint-down(md) {
            justify-content: center;
        }
    }

    .btn-compare {
        background-color: #fff;
        color: $navy;

        @include hover-focus {
            background-color: $navy;
            color: #fff;
        }
    }
}

.sort-by-row {
    background-color: #fff;
    border: 1px solid $grey-light;

    .filter-button {
        border-left: 1px solid $grey-light;
    }

    .btn {
        width: 100%;
        justify-content: center;
        font-weight: 400;
        color: $primary;

        span {
            margin-right: .5rem;
        }
    }

    .col-6 {
        padding-left: 0;
        padding-right: 0;
    }
}
.brand-title {
    font-weight: 500;
    display: block;
    padding-top: 1.5rem;
}
.brand-item {
    box-shadow: 0 0 0 1px $grey-light;
    margin: 0 0 1px 1px;
    border-radius: 0;
    background-color: $white;
    display: block;
    height: 100%;
    min-height: 146px;
    text-align: center;
    // display: flex;
    // flex-direction: column;
    // justify-content: center;
    // align-items: center;
    img {
        @extend .img-fluid;
    }
    @include hover-focus() {
        img {
            filter: grayscale(1);
            transition: all .5s;
        }
    }
}

.brand-img {
        display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100px;
    object-fit: contain;
}

.morecontent {
    display: none;
}

.show-read-more  {
    &.active {
        .morecontent {
            display: inline;
        }
        .moreellipses {
            display: none;
        }
    }
}

.btn-more {
    padding: 0;
    font-weight: 400;
    &:after {
        border: none;
        content: "\e5cf";
        @extend .material-icons;
        font-size: 1rem;
        vertical-align: -0.255em;
        transition: all .3s;
        margin-left: .5rem;
    }
    &.less {
        &:after {
            content: "\e5ce";
        }
    }
}

.subcategories {
    .category-item {
        box-shadow: 0 0 0 1px $grey-light;
        margin: 0 0 1px 1px;
        border-radius: 0;

        img {
            max-width: 56px;
        }
    }

    .row {
        @include media-breakpoint-down(lg) {
            margin-left: -1.5rem;
            margin-right: -1.5rem;
        }
    }
}